import {format} from "date-fns";
import {ru} from "date-fns/locale";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import * as React from "react";
import {ArticleJsonLd} from "gatsby-plugin-next-seo";
import {Link as GatsbyLink} from "gatsby";
import TagIcon from "../assets/svg/tag.svg";
import {capitalize} from "../utils/string";
import Layout from "../components/Layout/layout";
import Container from "../components/Layout/Container";
import Seo from "../components/SEO/SEO";
import {
    ArticleButton,
    Contents,
    Counters,
    Embed,
    Header,
    List,
    Paragraph,
    Personality,
    Quote,
    Table,
    Image,
} from "../components/Article/ArticleComponents";
import Section from "../components/Layout/Section";
import Slider from "../components/Common/Slider";
import SliderPagination from "../components/Common/Slider/Pagination";
import SliderNavigation from "../components/Common/Slider/Navigation";
import ArticleCard from "../components/Article/Card";
import * as articleStyles from "./article.module.css";

const articleComponents = {
    paragraph: Paragraph,
    header: Header,
    list: List,
    quote: Quote,
    image: Image,
    button: ArticleButton,
    contents: Contents,
    counters: Counters,
    embed: Embed,
    table: Table,
    personality: Personality,
};

const Article = ({pageContext: {articleData: article}, location}) => {
    const parsedContent = JSON.parse(article.content);

    let currentHeaderIdx = 0;

    const content = (parsedContent.blocks || []).map((block) => {
        if (block.type !== "header") {
            return block;
        }

        return {
            ...block,
            data: {
                ...block.data,
                idx: block.data.level < 3 ? currentHeaderIdx++ : 0,
            },
        };
    });

    return (
        <Layout>
            <Seo
                seo={{
                    title: article.title,
                    description: article.description,
                    openGraph: {title: "", description: "", image: [{url: article.featuredImage?.url || ""}]},
                }}
                article
            />
            <ArticleJsonLd
                url={location.href}
                headline={article.title}
                images={[article.featuredImage?.url]}
                datePublished={new Date(article.published_at).toISOString()}
                authorName="ITSM 365"
                publisherName="ITSM 365"
                publisherLogo={article.featuredImage?.url}
                description={article.description}
            />
            <Container>
                <article className={articleStyles.article}>
                    <div className={articleStyles.article__tags}>
                        <TagIcon />{" "}
                        {article.product && article.product.title && (
                            <GatsbyLink
                                to={`/blog?products=${article.product.slug}`}
                                className={articleStyles.article__tag}
                            >
                                {capitalize(article.product.title)}
                            </GatsbyLink>
                        )}{" "}
                        {article.tags.map((tag) => {
                            return (
                                <GatsbyLink
                                    to={`/blog?tags=${tag.slug}`}
                                    className={articleStyles.article__tag}
                                    key={tag.id}
                                >
                                    {tag.title}
                                </GatsbyLink>
                            );
                        })}
                        {article.industries.map((industry) => {
                            return (
                                <GatsbyLink
                                    to={`/blog?industries=${industry.slug}`}
                                    className={articleStyles.article__tag}
                                    key={industry.id}
                                >
                                    {industry.title}
                                </GatsbyLink>
                            );
                        })}
                    </div>
                    <h1 className={articleStyles.article__title}>{article.title}</h1>
                    <p className={articleStyles.article__note}>
                        {article.published_at && (
                            <time dateTime={new Date(article.published_at).toISOString()}>
                                {format(new Date(article.published_at), "d MMMM yyyy", {locale: ru})},
                            </time>
                        )}{" "}
                        <span>{article.readingTime || 0} мин читать</span>
                    </p>
                    {article.authors.length > 0 && (
                        <div className={articleStyles.article__authors}>
                            {article.authors.map((author) => {
                                const avatar = getImage(author.avatar?.localFile);

                                return (
                                    <div className={articleStyles.article__author} key={author.id}>
                                        <div className={articleStyles.article__authorAvatar}>
                                            {avatar && <GatsbyImage alt="" image={avatar} />}
                                        </div>
                                        <div className={articleStyles.article__authorName}>
                                            <p className={articleStyles.article__authorName}>{author.name}</p>
                                            <p className={articleStyles.article__authorPosition}>{author.position}</p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    <div className={articleStyles.article__blocks}>
                        {content.map(({id, type, data}) => {
                            const Component = articleComponents[type];
                            if (!Component) {
                                return undefined;
                            }

                            return <Component key={id} {...data} />;
                        })}
                    </div>
                </article>
            </Container>{" "}
            {article.relatedArticles.length > 0 && (
                <Section>
                    <Slider
                        alignLeft
                        sliderClassName={articleStyles.article__slider}
                        containerClassName={articleStyles.article__sliderContainer}
                        title="Рекомендуем"
                        navigation={<SliderNavigation />}
                        pagination={<SliderPagination />}
                    >
                        {article.relatedArticles.map((relatedArticle) => {
                            const articleData = {
                                title: relatedArticle.title,
                                description: relatedArticle.description,
                                date: new Date(relatedArticle.published_at),
                                readingTime: relatedArticle.readingTime,
                                url: `/blog/${relatedArticle.slug}`,
                                featuredImage: relatedArticle.featuredImage,
                            };

                            return <ArticleCard key={relatedArticle.id} {...articleData} />;
                        })}
                    </Slider>
                </Section>
            )}
        </Layout>
    );
};

export default Article;
